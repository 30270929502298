body {
  margin: 0;
  overflow: hidden;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  width: 100vw;
  height: 100vh;
  display: block;
}

#root {
  margin: 0;
  overflow: hidden;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  width: 100vw;
  height: 100vh;
  display: block;
}

#app {
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  height: 100vh;
  width: 100vw;
  margin: auto;
}

.tablecentered table tr th {
  background: #fff;

  border-bottom-style: none;
  width: 100%;
}
.tablecentered table tr th,
.tablecentered table tr th a,
.tablecentered table tr th a:hover {
  color: #fff;
  font-weight: bold;
  width: 100%;
}
.tablecentered table tbody tr th {
  vertical-align: top;
}
.tablecentered tr td,
.tablecentered tr th {
  padding: 4px 9px;
  /*border: 1px solid #fff;*/
  text-align: left; /* LTR */
}

.tablecentered tr.odd {
  background: #fff;
}
.tablecentered tr,
.tablecentered tr.even {
  background: #fff;
}
.tablecentered table ul.links {
  margin: 0;
  padding: 0;
  font-size: 1em;
}
.tablecentered table ul.links li {
  padding: 0 1em 0 0;
}

table.tablecentered {
  width: 100%;
  border: 0;
  border-spacing: 0;
  font-size: 0.857em;
  margin: 10px 0;
}

.tnimage {
  position: relative;
  width: 200px;
  height: 150px;

  border: 0px solid gray;
  z-index: 2000;
  display: block;
  padding: 0px;
  margin: 0px;
  margin-bottom: 24px;
}

div.tnimagetext {
  position: absolute;
  top: 151px;
  text-align: center;
  width: 200px;
  font-weight: bold;
  color: #fff;
  display: block;
  padding: 0px;
  margin: 0px;
  line-height: 0px;
}

.tnimagetext p {
  text-align: center;
  line-height: 15px;
  font-size: 15px;
  background-color: #333;

  text-decoration: none;
  color: #fff;
  margin: 0px;
  padding: 2px;
}

.tnimagetext a {
  text-align: center;
  line-height: 15px;
  font-size: 15px;
  color: #fff;
  margin: 0px;
  padding: 0px;
  font-style: none;
  text-decoration: none;
  font-weight: bold;
}

.error {
  color: red;
}
.mainparagraph {
  color: black;
}
.div {
  margin: 0;
  display: block;
}

.date {
  text-align: right;
  line-height: 10px;
  font-size: 12px;
  font-style: italic;
  color: #333;
  margin-top: 20px;
  margin-bottom: 20px;
}
